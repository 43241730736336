<template>
  <div class="orderconfirm-wrap">
    <div class="bread-wrap">
      <span @click="navigateback" class="backbtn"
        ><i class="el-icon-arrow-left"></i
      ></span>
      <span class="bread-title">订单确认</span>
    </div>
    <div class="order-wrap">
      <div class="order-left">
        <p class="title-wrap">
          <i class="iconfont icon-jiaofeizhangdan titleicon"></i
          ><span class="title">订单信息</span>
        </p>
        <div class="ff-detail">
          <span class="dtcontent">云手机规格</span
          ><span class="dttext">{{ detail.guige }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">云手机区域</span
          ><span class="dttext">{{ detail.region }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">购买数量</span
          ><span class="dttext">{{ "x" + detail.num }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">购买时长</span
          ><span class="dttext">{{ detail.sc }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">自动续费</span
          ><span class="dttext">{{ AutoRenewMap[payload.AutoRenew] }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">创建时间</span
          ><span class="dttext">{{ nowdate }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">支付方式</span
          ><span class="dttext">{{ payText }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtprice">合计费用</span
          ><span class="dtprice">{{ TotalAmount }} 元</span>
        </div>
        <el-button
          v-if="amountPay"
          @click="payNow"
          class="paybtn"
          size="small"
          type="primary"
          :disabled="isPay"
          >立即支付</el-button
        >
      </div>
      <div v-if="!amountPay" class="order-right">
        <div class="qrcode-wrap">
          <div class="qrcode" id="qrcodeImg"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatdate from "@/utils/format";
import navigation from "@/utils/navigation";
import QRCode from "qrcodejs2";
import { AutoRenewMap } from "@/utils/constants";
export default {
  name: "orderconfirm",
  data() {
    const date = new Date();
    return {
      TradeNo: "",
      detail: {},
      TotalAmount: 0,
      nowdate: formatdate(parseInt(date.getTime() / 1000), "YYYY-MM-DD"),
      amountPay: false,
      payText: "",
      payType: "",
      payload: {},
      AutoRenewMap: AutoRenewMap,
      isPay:false,
    };
  },
  mounted() {
    // console.log("pay params: " + JSON.stringify(this.$route.params))
    this.detail = this.$route.params.detail;
    this.payload = this.$route.params.payload;
    this.payType = this.detail.PayType;
    if (this.detail.PayType == "amount") {
      this.amountPay = true;
      this.payText = "余额支付";
    } else if (this.detail.PayType == "alipay_page") {
      this.amountPay = false;
      this.payText = "支付宝扫码支付";
    } else {
      this.amountPay = false;
      this.payText = "微信扫码支付";
    }
    // console.log(this.payload)
    // 创建订单
    this.$store
      .dispatch("pay/createordernumber", { ...this.payload })
      .then((res) => {
        if (res.RetCode == 0) {
          this.TradeNo = res.TradeNo;
          this.TotalAmount = res.TotalAmount / 100;
          //实际支付
          if (!this.amountPay) {
            this.getqrcode();
          }
        } else {
          this.$message.error(res.Message);
        }
      });
    this.getuserinfo();
  },
  beforeDestroy() {
    clearInterval(this.timmer);
  },
  activated() {
    this.getuserinfo();
  },
  methods: {
    qrcode(url) {
      return new QRCode("qrcodeImg", {
        width: 230,
        height: 230,
        text: url,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
    navigateback() {
      navigation.back();
    },
    navigation(url) {
      navigation.navigateTo({
        url,
      });
    },
    getpayconfirm() {
      this.timmer = setInterval(() => {
        this.$store
          .dispatch("pay/getpayconfirm", {
            TradeNo: this.TradeNo,
            PayType: this.payType,
            OrderType: "Phone",
          })
          .then((res) => {
            if (res.RetCode == 0 && res.Status == "Paid") {
              clearInterval(this.timmer);
              this.$message.success("支付成功！");
              this.navigation("phonelist");
            } else if (res.RetCode !== 0) {
              clearInterval(this.timmer);
            }
          });
      }, 1000);
    },
    getqrcode() {
      const payload = {
        TradeNo: this.TradeNo,
        PayType: this.payType,
        OrderType: "Phone",
      };
      this.$store
        .dispatch("pay/getpaymentparams", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.qrcode(res.PayReq);
            this.getpayconfirm();
          }
        });
    },
    payNow() {
      this.isPay = true;
      const payload = {
        TradeNo: this.TradeNo,
        PayType: "amount",
        OrderType: "Phone",
      };
      this.$store
        .dispatch("pay/getpaymentparams", { ...payload })
        .then((res) => {
          // console.log("amount pay response: " + JSON.stringify(res));
          if (res.RetCode == 0) {
            this.$message.success("支付成功！");
            if (this.payload.ProductId == "phone-matrix" || this.payload.ProductId == "phone-matrix8" || this.payload.ProductId == "phone-matrix16") {
              this.navigation("matrixphonelist");
            } else {
              this.navigation("phonelist");
            }
          } else {
            this.$message.error("支付失败！");
          }
        });
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>